@import "../../styles/variables.scss";

.SaleNotActive {
  width: 100%;
  img {
    width: 66%;
    height: min-content;
    align-self: center;
    margin-left: -10%;
  }
  h2 {
    margin: 0;
    .outer {
      position: absolute;
      height: 50px;
      margin-left: 5px;
      margin-top: 5px;
      animation-name: move;
      animation-duration: 0.32s;
      animation-iteration-count: infinite;
      animation-timing-function: ease-in-out;
      animation-direction: alternate;
      img {
        height: 100%;
      }
    }
  }
  .upper-block {
    display: flex;
    flex-direction: column;
    @media (min-width: $breakpoint-tablet) {
      flex-direction: row-reverse;
    }
    h1 {
      text-align: center;
      @media (min-width: $breakpoint-tablet) {
        text-align: left;
      }
      align-self: center;
    }
  }
  button {
    margin-top: 25px;
  }
}

@keyframes move {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(10deg);
  }
}
