@import "../../styles/variables.scss";
@import "../../styles/colors.scss";

.Footer {
  background-color: $page-bg-color;
  background-size: auto 100%;
  background-position-x: center;
  color: white;
  flex-shrink: 0;
  .content-container {
    position: static;
    width: 100%;
    bottom: 0;
    z-index: 1;
    padding-top: 12rem;
    padding-bottom: 2rem;
    display: block;
    @media (min-width: $breakpoint-tablet) {
      display: flex;
    }
    justify-content: center;
    .text-address {
      font-family: "Anton", sans-serif;
      letter-spacing: 1px;
      width: 100%;
      text-align: center;
      margin-bottom: 20px;
      @media (min-width: $breakpoint-tablet) {
        width: 55%;
        text-align: right;
        margin-right: 10px;
        margin-bottom: 0;
      }
      display: inline-block;
    }
    .social-media-container {
      display: inline-flex;
      @media (min-width: $breakpoint-tablet) {
        width: 45%;
        margin-left: 10px;
        justify-content: left;
      }
      width: 100%;
      justify-content: center;
      align-items: center;
      a {
        height: 40px;
        width: 40px;
        svg {
          height: 100%;
          width: 100%;
          fill: white;
        }
        &:not(:first-child) {
          margin-left: 10px;
        }
      }
    }
  }
}
