@import "../../styles/variables.scss";
@import "../../styles/colors.scss";
$icons-size: 40px;
.MyBuds {
  padding-top: 3rem;
  padding-bottom: 3rem;
  width: 66%;
  margin: auto;
  .buds-container {
    width: 100%;
    .bud-item {
      display: inline-flex;
      flex-direction: column;
      margin-bottom: 3rem;
      width: 100%;
      @media (min-width: $breakpoint-tablet) {
        width: 48%;
        margin: 0;
        &:nth-child(odd) {
          margin-right: 4%;
          margin-bottom: 2%;
        }
      }
      img {
        width: 100%;
      }
      p {
        font-size: 1.25rem;
        display: inline-flex;
      }
      .transfer-button {
        margin-left: 10px;
        height: $icons-size;
        width: $icons-size;
        font-size: 5rem;
        color: $body-text-color;
      }
      svg {
        height: $icons-size;
        width: $icons-size;
        cursor: pointer;
      }
      .bud-item-info {
        text-align: left;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .left-align {
          display: inline-flex;
        }
        .right-align {
          display: inline-flex;
          input:invalid {
            border: red solid 2px;
          }
        }
      }
    }
  }
}
.bud-item:nth-child(odd) {
  margin-right: 2%;
  margin-bottom: 2%;
}
.MyBuds.loading {
  width: 50%;
}
