@import url("https://fonts.googleapis.com/css2?family=Anton&family=Roboto&display=swap");
@import "./styles/variables.scss";
@import "./styles/colors.scss";

.App {
  text-align: center;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  p {
    font-family: $body-font;
    font-size: $body-font-size * 0.75;
    @media (min-width: $breakpoint-tablet) {
      font-size: $body-font-size;
    }
    line-height: 35px;
    color: $body-text-color;
  }
  h1 {
    font-family: $title-font;
    font-size: $title-font-size * 1.33;
    letter-spacing: 1px;
    color: $title-color;
    font-weight: 500;
  }
  h2 {
    font-family: $title-font;
    letter-spacing: 2px;
    font-size: $title-font-size;
    color: $title-color;
  }
  h3 {
    font-family: $title-font;
    letter-spacing: 1px;
    font-size: $body-font-size;
  }
  a {
    /* color: #a249d8; */
    color: white;
    text-decoration: underline;
  }
  .router-outlet {
    padding-top: 5rem;
    background-color: $page-bg-color;
    flex-grow: 1;
  }
  button {
    background-color: $accordion-button-bg-color;
    color: $accordion-button-text-color;
  }
  button:hover {
    background-color: $accordion-button-bg-hover-color;
    color: $accordion-button-text-hover-color;
  }
}
