@import "../../styles/colors.scss";
@import "../../styles/variables.scss";

.Faq {
  background-color: $faq-bg-color;
  h2 {
    margin-top: 0;
  }
  .accordion-container {
    width: 100%;
    margin: auto;
    .MuiPaper-root {
      background-color: $accordion-header-bg-color;
      color: $accordion-header-text-color;
      border: 1px solid $accordion-border-color;
      margin-bottom: -2px;
      text-align: left;
      box-shadow: none;
    }
    .MuiButtonBase-root {
      color: $accordion-header-text-color;
    }
    .MuiIconButton-label {
      color: $accordion-header-text-color;
    }
    .MuiAccordionDetails-root {
      display: block;
      text-align: left;
      background-color: $accordion-bg-color;
      p {
        color: $accordion-text-color;
      }
    }
    .MuiAccordionSummary-content {
      margin: 0px 0;
      h3 {
        margin-top: 0.66em;
        margin-bottom: 0.66em;
      }
    }
  }
}
