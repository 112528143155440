@import "../../styles/colors.scss";

.BigHomeCta {
    .cta {
        width: auto;
        background-color: $home-cta-bg-color;
        h1 {
            margin: 0;
            font-size: 2.5rem;
        }
    }
}