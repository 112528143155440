@import "../../styles/variables.scss";
@import "../../styles/colors.scss";

.WhatIsBudheads {
  background-color: $what-are-budheads-bg-color;
  h2 {
    text-transform: uppercase;
  }
  .side-by-side {
    width: 100%;
    display: flex;
    flex-direction: column;
    @media (min-width: $breakpoint-tablet) {
      flex-direction: row;
    }
    p {
      margin-top: auto;
      margin-bottom: auto;
      @media (min-width: $breakpoint-tablet) {
        margin-right: 66px;
      }
    }
    img {
      border-radius: 50%;
      width: 50%;
      align-self: center;
      margin-top: 25px;
    }
  }
}
