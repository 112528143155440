@import "../../styles/colors.scss";
@import "../../styles/variables.scss";

.team-container {
  background-color: $page-bg-color;
  width: 100% !important;
  padding: 0;
  .container {
    width: 85%;
    @media (min-width: $breakpoint-tablet) {
      width: 70%;
    }
    margin: auto;
    margin: auto;
    padding-top: 2rem;
    padding-bottom: 4rem;
    .cards-container {
      display: flex;
      text-align: left;
      justify-content: space-between;
      width: 100%;
      flex-flow: column;
      align-items: center;
      @media (min-width: $breakpoint-desktop) {
        flex-flow: row;
        height: 700px;
      }
      .card {
        color: #eaeaea;
        width: 80%;
        height: auto;
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        background-color: darken($color: $dark, $amount: 5%);
        &:not(:last-child) {
          margin-bottom: 2rem;
        }
        @media (min-width: $breakpoint-desktop) {
          width: 31%;
          margin-bottom: 0 !important;
          height: 100%;
        }
        .card-image {
          height: 335px;
        }
        .MuiTypography-colorTextSecondary {
          color: #eaeaea;
          font-family: $body-font;
          font-size: $body-font-size;
        }
        .MuiButton-textPrimary {
          color: $accent;
          text-decoration: none;
        }
        .MuiCardActionArea-focusHighlight {
          display: none !important;
        }
        .MuiCardActions-root {
          align-items: flex-end;
          justify-content: space-between;
          a {
            font-family: $body-font;
            font-size: 12px;
          }
        }
        h2 {
          color: $team-section-title-color;
        }
      }
      .MuiButtonBase-root.MuiCardActionArea-root {
        display: contents;
      }
    }
  }
}
