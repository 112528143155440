@import "../../styles/variables.scss";
@import "../../styles/colors.scss";

.connection-container {
  width: 85%;
  margin: auto;
  max-width: 1440px;
  .connected-message {
    margin-top: -2rem;
    margin-bottom: 3rem;
    & > * {
      display: block;
    }
    a {
      color: $button-font-color;
      text-decoration: none;
      cursor: pointer;
    }
  }
  .connect-button-container {
    height: 100px;
    background-size: contain;
    background-position-x: center;
  }
}

.not-connected-message {
  width: 80%;
  margin: auto;
  padding-bottom: 3rem;
  .button-container {
    width: $button-width;
    height: $button-height;
    margin: auto;
    position: relative;
    cursor: pointer;

    .button-image {
      width: 100%;
      height: 100%;
    }

    .connect-button {
      /* font-family: $button-font; */
      font-size: $button-font-size;
      color: $button-font-color;
      font-weight: bold;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      line-height: $button-height;
      width: 100%;
    }
  }
}
