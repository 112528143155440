@import '../../styles/colors.scss';
@import '../../styles/variables.scss';

.header-container {
  position: absolute;
  top: 0;
  .logo {
    .logo-link {
      img {
        width: 85px;
        height: 80px;
      }
    }
  }
  .list-menu {
    width: 98.3vw;
    position: absolute;
    display: none;
    @media (min-width: $breakpoint-tablet) {
        display: initial;
      }
    .MuiList-root {
      width: 75%;
      margin-right: auto;
      margin-left: auto;
      display: flex;
      justify-content: center;
      .MuiListItem-root {
        text-align: center;
        white-space: nowrap;
        span {
          font-family: $title-font;
          color: $header-desktop-link-color;
          font-size: $body-font-size;
        }
      }
    }
  }
  .hamburger-menu {
    position: absolute;
    right: -95vw;
    top: 16px;
    display: initial;
    background-color: $mobile-menu-links-color;
    @media (min-width: $breakpoint-tablet) {
        display: none;
      }
    .menu-button {
      svg {
        font-size: 3rem;
        color: $mobile-menu-button-color;
      }
    }
  }
}

.menu-list {
  width: 200px;
  a {
    text-align: right;
  }
  .MuiButtonBase-root {
    padding: 1rem;
    .MuiTypography-root {
      font-family: $title-font;
      color: $mobile-menu-links-color;
      font-size: $body-font-size;
    }
  }

}
