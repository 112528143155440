@import "../../styles/colors.scss";

.WeAreAMovement {
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
  margin-bottom: -5px;
  background-color: $we-are-a-movement-bg-color;
  h2 {
      text-transform: uppercase;
  }
  .images-container {
    width: 100%;
    img {
      width: 25%;
      height: 25%;
    }
  }
  p {
    padding: 1rem 10%;
  }
}
