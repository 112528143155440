@import "../../styles/colors.scss";
@import "../../styles/variables.scss";

$border-size: 5px;

.Minter {
  .accordion-container {
    width: 100%;
    margin: auto;
    .MuiPaper-root {
      background-color: $accordion-header-bg-color;
      color: $accordion-header-text-color;
      //border: 1px solid $accordion-border-color;
      text-align: left;
      box-shadow: none;
    }
    .MuiPaper-root.completed {
      background-color: $accordion-header-bg-color-completed !important;
      .MuiAccordionSummary-root {
        opacity: 0.5;
      }
      .MuiAccordionDetails-root {
        border-left: $border-size solid $accordion-header-bg-color-completed !important;
        border-right: $border-size solid $accordion-header-bg-color-completed !important;
      }
    }
    .MuiPaper-root:not(.completed):last-of-type {
      background-color: $accordion-header-bg-color-to-be-completed;
      .MuiAccordionDetails-root {
        border-left: $border-size solid $accordion-header-bg-color-to-be-completed;
        border-right: $border-size solid $accordion-header-bg-color-to-be-completed;
      }
    }
    .MuiPaper-root.completed + .MuiPaper-root {
      background-color: $accordion-header-bg-color-to-be-completed;
      .MuiAccordionDetails-root {
        border-left: $border-size solid $accordion-header-bg-color-to-be-completed;
        border-right: $border-size solid $accordion-header-bg-color-to-be-completed;
      }
    }
    .MuiPaper-root.Mui-expanded {
      margin: 0;
    }
    .MuiPaper-root:after {
      top: -1px;
      left: 0;
      right: 0;
      height: 1px;
      content: "" !important;
      opacity: 1;
      position: absolute;
      transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      background-color: rgba(0, 0, 0, 0.12);
    }
    .MuiAccordionDetails-root {
      display: block;
      text-align: left;
      p {
        color: $accordion-body-text-color;
      }
    }
    .MuiButtonBase-root {
      color: $accordion-header-text-color;
    }
    .MuiIconButton-label {
      color: $accordion-header-text-color;
    }
    .MuiButton-root {
      margin-right: 10px;
    }
    .outer {
      position: absolute;
      height: 50px;
      margin-left: 5px;
      margin-top:-10px;
      animation-name: move;
      animation-duration: 0.32s;
      animation-iteration-count: infinite;
      animation-timing-function: ease-in-out;
      animation-direction: alternate;
      img {
        height: 100%;
      }
    }
    .MuiAccordionDetails-root {
      background-color: $accordion-bg-color;
      color: $accordion-text-color;
      .error {
        color: $flow-error-text-color;
        font-size: 1.1rem;
        margin: 5px 0 0 0;
        a {
          color: $accordion-header-text-color;
        }
      }
      .info {
        color: yellow;
      }
      .success {
        color: green;
      }
    }
    .MuiAccordionSummary-content {
      margin: 0px 0;
      h3 {
        margin-top: 0.66em;
        margin-bottom: 0.66em;
      }
    }
    .quantity-header {
      .MuiAccordionSummary-content {
        justify-content: space-between;
        margin-right: 10px;
        .quantity-selector {
          display: flex;
          align-items: center;
          cursor: pointer;
          h3 {
            margin-left: 30px;
            margin-right: 30px;
          }
        }
      }
    }
    .MuiSvgIcon-root {
      font-size: 2.5rem;
      vertical-align: sub;
    }
  }
}

@keyframes move {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(10deg);
  }
}
