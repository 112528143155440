@import "../../styles/colors.scss";

.JoinDiscord {
    background-color: $join-discord-bg-color;
    color: $join-discord-text-color;
    margin-bottom: -30px;
    h2 {
        text-transform: uppercase;
        margin-top: 0;
    }
    .discord-link {
        padding: 20px 60px;
        background-color: $discord-button-color;
        border-radius: 5px;
        text-decoration: none;
        margin: 1rem 0;
        display: inline-block;
    }
}