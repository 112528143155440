@import "../../styles/colors.scss";
@import "../../styles/variables.scss";

.Roadmap {
  background-color: $roadmap-bg-color;
  color: $roadmap-text-color;
  padding: 3rem 5%;
  @media (min-width: $breakpoint-tablet) {
    padding: 3rem 10%;
  }
  .roadmap-items {
    .roadmap-item {
      display: flex;
      align-items: center;
      text-align: left;
      justify-content: space-between;
      &:not(:last-child) {
        margin-bottom: 3rem;
      }
      h3 {
        display: inline-flex;
        margin: 0;
        color: $roadmap-value-color;
        text-align: center;
        padding-right: 10px;
        padding-left: 10px;
      }
      p {
        width: 50%;
        display: inline-flex;
        margin: 0;
      }
      .icon {
        width: 100px;
        display: inline-flex;
        align-items: center;
        .frame {
          width: 100%;
          position: relative;
        }
        .shape {
          width: 80%;
          position: relative;
          left: -90%;
        }
        .icon-img {
          width: 45%;
          height: 45%;
          position: relative;
          left: -151%;
        }
      }
    }
  }
}
