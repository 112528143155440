@import "../../styles/variables.scss";

.Mint {
  padding-top: 3rem;
  padding-bottom: 3rem;
  width: 90%;
  @media (min-width: $breakpoint-tablet) {
    width: 50%;
  }
  margin: auto;
}
