$dark: #2a2d34;
$primary: #78ba5f;
$secondary: #FF7F11;
$third: #a4a8d1;
$accent: #ffbe0b;
$light: #eaeaea;
$dark-gray: #3a3a3a;
$completed: $primary;
$gray: #575557;

$button-font-color: $dark;


$page-bg-color: $gray;
$title-color: $light;
$body-text-color: $light;
$home-cta-text-color: $dark;
$home-cta-bg-color: $primary;
$accordion-header-bg-color: $light;
$accordion-header-bg-color-completed: $completed;
$accordion-header-bg-color-to-be-completed: $accent;
$accordion-header-text-color: $dark;
$accordion-body-text-color: $dark;
$accordion-border-color: $light;
$accordion-button-text-color: $dark;
$accordion-button-text-hover-color: $dark;
$accordion-button-bg-color: $primary;
$accordion-button-bg-hover-color: $secondary;
$accordion-bg-color: $light;
$accordion-text-color: $dark;
$discord-button-color: #7289da;
$faq-bg-color: $dark-gray;
$join-discord-bg-color: $page-bg-color;
$join-discord-text-color: $light;
$we-are-a-movement-bg-color: $dark-gray;
$what-are-budheads-bg-color: $dark-gray;
$roadmap-bg-color: $dark;
$roadmap-text-color: $light;
$roadmap-value-color: $light;
$header-desktop-link-color: $light;
$mobile-menu-bg-color: $header-desktop-link-color;
$mobile-menu-button-color: $header-desktop-link-color;
$mobile-menu-links-color: $page-bg-color;
$flow-error-text-color: $secondary;
$team-section-title-color: $light;