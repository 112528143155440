$breakpoint-desktop: 1200px;
$breakpoint-tablet: 768px;
$breakpoint-mobile: 480px;
$title-font: 'Anton', sans-serif;
$title-font-size: 2.5rem;
$body-font: 'Roboto', sans-serif;
$body-font-size: 24px;
$button-font: 'Anton', sans-serif;
$button-font-size: 1.3rem;
$button-width: 200px;
$button-height: 70px;
