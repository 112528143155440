@import "../../styles/variables.scss";

.AccentSeparator {
  position: absolute;
  left: 50%;
  padding: 0 !important;
  transform: translate(-25px, -25px);
  @media (min-width: $breakpoint-tablet) {
    transform: translate(-50px, -50px);
  }
  img {
    @media (min-width: $breakpoint-tablet) {
        width: 100px;
        height: 100px;
    }
    width: 50px;
    height: 50px;
  }
}
